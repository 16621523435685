"use client";
import { Link } from "@chakra-ui/next-js";
import {
  Box,
  Button,
  Container,
  HStack,
  useDisclosure,
  IconButton,
  Stack,
  Icon,
} from "@chakra-ui/react";
import Image from "next/image";
import logo from "@/assets/horizontal-logo.png";
import { HamburgerIcon, CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import StickyPhoneBanner from "./StickyPhoneBanner";
import { formatPhoneNumber } from "@/utils/helpers";
import { MINDSET_PHONE_NUMBER } from "@/lib/constants";

const MainMenuLink = ({ href, label }: { href: string; label: string }) => {
  return (
    <Link
      href={href}
      cursor="pointer"
      fontSize="18px"
      fontWeight="500"
      color="#071920"
      _hover={{
        textDecoration: "none",
        color: "black",
      }}
    >
      {label}
    </Link>
  );
};

const MAIN_MENU_LINKS = [
  {
    href: "/about",
    label: "About Us",
  },
  {
    href: "/blog",
    label: "Blog",
  },
];

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box py="23px" bg="white" borderBottom="1px solid #B9CAD0">
        <Container maxW="container.xl">
          <HStack alignItems="center" justifyContent="space-between">
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack gap="48px" alignItems="center">
              <Link href="/">
                <Image src={logo} height={40} width={205} alt="Mindset" />
              </Link>
            </HStack>

            <HStack gap="42px" alignItems="center" hideBelow="lg">
              {MAIN_MENU_LINKS.map((page) => (
                <MainMenuLink
                  key={page.href}
                  href={page.href}
                  label={page.label}
                />
              ))}
            </HStack>
            <HStack gap="24px">
              <MainMenuLink href="/login" label="Log In" />
              <Button
                as={Link}
                href={`tel:${MINDSET_PHONE_NUMBER}`}
                height="50px"
                py="16px"
                px="24px"
                color="brand.secondary"
                fontSize="16px"
                fontWeight="700"
                borderRadius="8px"
                borderWidth="1px"
                borderColor="brand.secondary"
                bg="transparent"
                leftIcon={<Icon as={PhoneIcon} />}
                _hover={{
                  bg: "brand.secondary",
                  textDecoration: "none",
                  color: "white",
                }}
                hideBelow="lg"
              >
                {formatPhoneNumber(MINDSET_PHONE_NUMBER)}
              </Button>
            </HStack>
          </HStack>
        </Container>
        {isOpen ? (
          <Container hideFrom="lg" maxW="container.xl">
            <Stack as={"nav"} spacing={4} mt="16px">
              {MAIN_MENU_LINKS.map((link) => (
                <Link
                  key={link.href}
                  href={link.href}
                  _hover={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  {link.label}
                </Link>
              ))}
            </Stack>
          </Container>
        ) : null}
      </Box>
      <StickyPhoneBanner />
    </>
  );
};

export default Header;
