import { isBrowser } from "@/utils/helpers";

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  if (isBrowser() && window.fbq) {
    window.fbq("track", "PageView");
  }
};

export const trackMetaPixelEvent = (name: string) => {
  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && isBrowser() && window.fbq) {
    window.fbq("track", name);
  }
};
