"use client";
import { POSTHOG_HOST } from "@/lib/constants";
import { isBrowser } from "@/utils/helpers";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (isBrowser()) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: POSTHOG_HOST,
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    capture_pageleave: true,
  });
}
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
